.centre {
  text-align: center;
}
nav {
  padding: 1rem 1rem 0 0 ;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
nav a {
  color: gray;
  font-size: medium;
  margin: 0 10px 0;
}
.hero h1 {
  font-size: xx-large;
  margin-top: 10%;
}
.hero {
  margin-bottom: 18%;
  
}

.button {
  display: flex;
  overflow: hidden;

  margin: 10px;
  padding: 12px 12px;

  cursor: pointer;
  user-select: none;
  transition: all 150ms linear;
  text-align: center;
  white-space: nowrap;
  text-decoration: none !important;
  text-transform: none;
  text-transform: capitalize;
  color: #202129;
  background-color: #f2f2f2;
  border: 0 none;
  border-radius: 36px;

  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  justify-content: center;
  align-items: center;
  flex: 0 0 160px;

  box-shadow: 2px 5px 10px var(#e4e4e4);
}
.button:focus {
  outline: 1px dotted #959595;
  outline-offset: -4px;
}
.button:hover {
  color: #202129;
  background-color: #e1e2e2;
  opacity: 1;
}


.section1 {
  margin: 5% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #04619f 74%);  padding: 5%;
  color: white;
}
.largetext {
    font-size: 1.4rem;
}
.largeheader {
    font-size: xxx-large;
}
html {
  scroll-behavior: smooth
}