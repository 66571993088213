
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-page-header-index-pageHeader {
  background: #fff;
  padding: 16px 32px 0 32px;
  border-bottom: 1px solid #e8e8e8;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-wide {
  max-width: 1200px;
  margin: auto;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-detail {
  display: flex;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-row {
  display: flex;
  width: 100%;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-breadcrumb {
  margin-bottom: 16px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-tabs {
  margin: 0 0 0 -8px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-tabs .ant-tabs-bar {
  border-bottom: none;
  margin-bottom: 1px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-logo {
  flex: 0 1 auto;
  margin-right: 16px;
  padding-top: 1px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-logo > img {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: block;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-title {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action {
  margin-left: 56px;
  min-width: 266px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group:not(:last-child),
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn:not(:last-child) {
  margin-right: 8px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group > .ant-btn {
  margin-right: 0;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-title,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-content {
  flex: auto;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-main {
  flex: 0 1 auto;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-main {
  width: 100%;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-title,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action {
  margin-bottom: 16px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-logo,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-content,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
  margin-bottom: 16px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
  text-align: right;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
  margin-left: 88px;
  min-width: 242px;
}
@media screen and (max-width: 1200px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
    margin-left: 44px;
  }
}
@media screen and (max-width: 992px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-row {
    display: block;
  }
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action,
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
    margin-left: 0;
    text-align: left;
  }
}
@media screen and (max-width: 576px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-detail {
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group,
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn {
    display: block;
    margin-bottom: 8px;
  }
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group > .ant-btn {
    display: inline-block;
    margin-bottom: 0;
  }
}

.antd-pro-components-page-header-wrapper-grid-content-main {
  width: 100%;
  height: 100%;
  min-height: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.antd-pro-components-page-header-wrapper-grid-content-main.antd-pro-components-page-header-wrapper-grid-content-wide {
  max-width: 1200px;
  margin: 0 auto;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-page-header-wrapper-index-content {
  margin: 24px 24px 0;
}
@media screen and (max-width: 576px) {
  .antd-pro-components-page-header-wrapper-index-content {
    margin: 24px 0 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-exception-index-exception {
  display: flex;
  align-items: center;
  height: 80%;
  min-height: 500px;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock:before,
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock:after {
  content: ' ';
  display: table;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgEle {
  height: 360px;
  width: 100%;
  max-width: 430px;
  float: right;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content {
  flex: auto;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content h1 {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content .antd-pro-components-exception-index-desc {
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content .antd-pro-components-exception-index-actions button:not(:last-child) {
  margin-right: 8px;
}
@media screen and (max-width: 1200px) {
  .antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
    padding-right: 88px;
  }
}
@media screen and (max-width: 576px) {
  .antd-pro-components-exception-index-exception {
    display: block;
    text-align: center;
  }
  .antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
    padding-right: 0;
    margin: 0 auto 24px;
  }
}
@media screen and (max-width: 480px) {
  .antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
    margin-bottom: -24px;
    overflow: hidden;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-layouts-auth-layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5;
}
.antd-pro-layouts-auth-layout-lang {
  text-align: right;
  width: 100%;
  height: 40px;
  line-height: 44px;
}
.antd-pro-layouts-auth-layout-lang .ant-dropdown-trigger {
  margin-right: 24px;
}
.antd-pro-layouts-auth-layout-content {
  padding: 32px 0;
  flex: 1 1;
}
@media (min-width: 768px) {
  .antd-pro-layouts-auth-layout-container {
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .antd-pro-layouts-auth-layout-content {
    padding: 30px 0 10px 0;
    width: 500px;
    margin: 20px 0 0 170px;
    background: #ffffff52;
    position: absolute;
  }
}
.antd-pro-layouts-auth-layout-top {
  text-align: center;
}
.antd-pro-layouts-auth-layout-header {
  width: 100%;
  height: 130px;
  line-height: 50px;
}
.antd-pro-layouts-auth-layout-header a {
  text-decoration: none;
}
.antd-pro-layouts-auth-layout-logo {
  height: 70px;
}
.antd-pro-layouts-auth-layout-title {
  font-size: 31px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 4px;
}
.antd-pro-layouts-auth-layout-desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 12px;
  margin-bottom: 40px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-global-footer-index-globalFooter {
  padding: 0 16px;
  margin: 48px 0 24px 0;
  text-align: center;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links {
  margin-bottom: 8px;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links a {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links a:not(:last-child) {
  margin-right: 40px;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links a:hover {
  color: rgba(0, 0, 0, 0.65);
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-copyright {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-global-header-index-header {
  height: 64px;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.antd-pro-components-global-header-index-logo {
  height: 64px;
  line-height: 64px;
  vertical-align: top;
  display: inline-block;
  padding: 0 0 0 24px;
  cursor: pointer;
  font-size: 20px;
}
.antd-pro-components-global-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
}
.antd-pro-components-global-header-index-menu .anticon {
  margin-right: 8px;
}
.antd-pro-components-global-header-index-menu .ant-dropdown-menu-item {
  min-width: 160px;
}
.antd-pro-components-global-header-index-trigger {
  font-size: 20px;
  height: 64px;
  cursor: pointer;
  -webkit-transition: all 0.3s, padding 0s;
  transition: all 0.3s, padding 0s;
  padding: calc((64px - 20px) / 2) 24px;
}
.antd-pro-components-global-header-index-trigger:hover {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right {
  float: right;
  height: 100%;
  overflow: hidden;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action {
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 100%;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action > i {
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.65);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action:hover {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action.opened {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-search {
  padding: 0 12px;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-search:hover {
  background: transparent;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
  margin: calc((64px - 24px) / 2) 0;
  margin-right: 8px;
  color: #1890ff;
  background: rgba(255, 255, 255, 0.85);
  vertical-align: top;
}
.antd-pro-components-global-header-index-dark {
  height: 64px;
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action > i {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action:hover,
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action.opened {
  background: #1890ff;
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action .ant-badge {
  color: rgba(255, 255, 255, 0.85);
}
@media only screen and (max-width: 768px) {
  .antd-pro-components-global-header-index-header .ant-divider-vertical {
    vertical-align: unset;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-name {
    display: none;
  }
  .antd-pro-components-global-header-index-header i.antd-pro-components-global-header-index-trigger {
    padding: 22px 12px;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-logo {
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-right {
    position: absolute;
    right: 12px;
    top: 0;
    background: #fff;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
    margin-right: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-header-dropdown-index-container > * {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
@media screen and (max-width: 480px) {
  .antd-pro-components-header-dropdown-index-container {
    width: 100% !important;
  }
  .antd-pro-components-header-dropdown-index-container > * {
    border-radius: 0 !important;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-notice-icon-notice-list-list {
  max-height: 400px;
  overflow: auto;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
  cursor: pointer;
  padding-left: 24px;
  padding-right: 24px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-meta {
  width: 100%;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-avatar {
  background: #fff;
  margin-top: 4px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-iconElement {
  font-size: 32px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item.antd-pro-components-notice-icon-notice-list-read {
  opacity: 0.4;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item:last-child {
  border-bottom: 0;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item:hover {
  background: #e6f7ff;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-title {
  font-weight: normal;
  margin-bottom: 8px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-description {
  font-size: 12px;
  line-height: 1.5;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-datetime {
  font-size: 12px;
  margin-top: 4px;
  line-height: 1.5;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-extra {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  margin-right: 0;
  margin-top: -1.5px;
}
.antd-pro-components-notice-icon-notice-list-notFound {
  text-align: center;
  padding: 73px 0 88px 0;
  color: rgba(0, 0, 0, 0.45);
}
.antd-pro-components-notice-icon-notice-list-notFound img {
  display: inline-block;
  margin-bottom: 16px;
  height: 76px;
}
.antd-pro-components-notice-icon-notice-list-clear {
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #e8e8e8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}
.antd-pro-components-notice-icon-notice-list-clear:hover {
  color: rgba(0, 0, 0, 0.85);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-notice-icon-index-popover {
  width: 336px;
}
.antd-pro-components-notice-icon-index-noticeButton {
  cursor: pointer;
  display: inline-block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.antd-pro-components-notice-icon-index-icon {
  padding: 4px;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-nav-scroll {
  text-align: center;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-bar {
  margin-bottom: 4px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-header-search-index-headerSearch .anticon-search {
  cursor: pointer;
  font-size: 16px;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input {
  -webkit-transition: width 0.3s, margin-left 0.3s;
  transition: width 0.3s, margin-left 0.3s;
  width: 0;
  background: transparent;
  border-radius: 0;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input .ant-select-selection {
  background: transparent;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input input {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  box-shadow: none !important;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input,
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input:hover,
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input:focus {
  border-bottom: 1px solid #d9d9d9;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input.antd-pro-components-header-search-index-show {
  width: 300px;
  margin-left: 8px;
}
.antd-pro-components-header-search-index-guideLines li:hover {
  color: unset !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-sider-menu-index-logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  padding-left: 24px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #002140;
  overflow: hidden;
}
.antd-pro-components-sider-menu-index-logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}
.antd-pro-components-sider-menu-index-logo h1 {
  color: white;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  margin: 0 0 0 12px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.antd-pro-components-sider-menu-index-sider {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-fixSiderbar {
  position: fixed;
  top: 0;
  left: 0;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-fixSiderbar .ant-menu-root {
  overflow-y: auto;
  height: calc(100vh - 64px);
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  background-color: white;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .antd-pro-components-sider-menu-index-logo {
  background: white;
  box-shadow: 1px 1px 0 0 #e8e8e8;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .antd-pro-components-sider-menu-index-logo h1 {
  color: #1890ff;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .ant-menu-light {
  border-right-color: transparent;
}
.antd-pro-components-sider-menu-index-icon {
  width: 14px;
  margin-right: 10px;
}
.top-nav-menu li.ant-menu-item {
  height: 64px;
  line-height: 64px;
}
.drawer .drawer-content {
  background: #001529;
}
.ant-menu-inline-collapsed > .ant-menu-item .sider-menu-item-img + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .sider-menu-item-img + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .sider-menu-item-img + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-item .sider-menu-item-img + span,
.ant-menu-submenu-title .sider-menu-item-img + span {
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-top-nav-header-index-head {
  width: 100%;
  -webkit-transition: background 0.3s, width 0.2s;
  transition: background 0.3s, width 0.2s;
  height: 64px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.antd-pro-components-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal {
  line-height: 64px;
  height: 100%;
}
.antd-pro-components-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal .ant-menu-submenu-title {
  height: 100%;
}
.antd-pro-components-top-nav-header-index-head.antd-pro-components-top-nav-header-index-light {
  background-color: #fff;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main {
  display: flex;
  height: 64px;
  padding-left: 24px;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main.antd-pro-components-top-nav-header-index-wide {
  max-width: 1200px;
  margin: auto;
  padding-left: 0;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main .antd-pro-components-top-nav-header-index-left {
  flex: 1 1;
  display: flex;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main .antd-pro-components-top-nav-header-index-right {
  width: 324px;
}
.antd-pro-components-top-nav-header-index-logo {
  width: 165px;
  height: 64px;
  position: relative;
  line-height: 64px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
}
.antd-pro-components-top-nav-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}
.antd-pro-components-top-nav-header-index-logo h1 {
  color: #fff;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  margin: 0 0 0 12px;
  font-weight: 400;
}
.antd-pro-components-top-nav-header-index-light h1 {
  color: #002140;
}
.antd-pro-components-top-nav-header-index-menu {
  border: none;
  height: 64px;
  line-height: 64px;
}

.antd-pro-layouts-header-fixedHeader {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-layouts-basic-layout-content {
  margin: 24px;
  padding-top: 64px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-containers-login-login-main {
  max-width: 368px;
  margin: 0 auto;
}
@media screen and (max-width: 576px) {
  .antd-pro-containers-login-login-main {
    width: 95%;
  }
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-icon {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-icon:hover {
  color: #1890ff;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-other .antd-pro-containers-login-login-register {
  float: right;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-forgot {
  float: right;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-button {
  width: 100%;
}

.centre {
  text-align: center;
}
nav {
  padding: 1rem 1rem 0 0 ;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
nav a {
  color: gray;
  font-size: medium;
  margin: 0 10px 0;
}
.hero h1 {
  font-size: xx-large;
  margin-top: 10%;
}
.hero {
  margin-bottom: 18%;
  
}

.button {
  display: flex;
  overflow: hidden;

  margin: 10px;
  padding: 12px 12px;

  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: all 150ms linear;
  transition: all 150ms linear;
  text-align: center;
  white-space: nowrap;
  text-decoration: none !important;
  text-transform: none;
  text-transform: capitalize;
  color: #202129;
  background-color: #f2f2f2;
  border: 0 none;
  border-radius: 36px;

  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  justify-content: center;
  align-items: center;
  flex: 0 0 160px;

  box-shadow: 2px 5px 10px var(#e4e4e4);
}
.button:focus {
  outline: 1px dotted #959595;
  outline-offset: -4px;
}
.button:hover {
  color: #202129;
  background-color: #e1e2e2;
  opacity: 1;
}


.section1 {
  margin: 5% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  background-image: -webkit-linear-gradient(303deg, #000000 0%, #04619f 74%);
  background-image: linear-gradient(147deg, #000000 0%, #04619f 74%);  padding: 5%;
  color: white;
}
.largetext {
    font-size: 1.4rem;
}
.largeheader {
    font-size: xxx-large;
}
html {
  scroll-behavior: smooth
}
.ant-drawer-body {
    padding: 0px !important;
}

.ql-snow .ql-editor h5 {
    font-size: 1.4em;
}


