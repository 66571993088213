/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.headerSearch :global(.anticon-search) {
  cursor: pointer;
  font-size: 16px;
}
.headerSearch .input {
  transition: width 0.3s, margin-left 0.3s;
  width: 0;
  background: transparent;
  border-radius: 0;
}
.headerSearch .input :global(.ant-select-selection) {
  background: transparent;
}
.headerSearch .input input {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  box-shadow: none !important;
}
.headerSearch .input,
.headerSearch .input:hover,
.headerSearch .input:focus {
  border-bottom: 1px solid #d9d9d9;
}
.headerSearch .input.show {
  width: 300px;
  margin-left: 8px;
}
.guideLines li:hover {
  color: unset !important;
}
